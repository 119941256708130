<template>
  <form>
    <v-row>
      <v-col cols="12" sm="12" md="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" class="py-0">
                <v-subheader>First Name <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-text-field dense outlined flat v-model="form.firstName" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" class="py-0">
                <v-subheader>Last Name</v-subheader>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-text-field dense outlined flat v-model="form.lastName" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" class="py-0">
                <v-subheader>Email <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-text-field dense outlined flat v-model="form.email" type="email" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" class="py-0">
                <v-subheader>Password <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-text-field dense outlined flat v-model="form.password" type="password" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" class="py-0">
                <v-subheader>Mobile</v-subheader>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-text-field dense outlined flat v-model="form.mobile" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" class="py-0">
                <v-subheader>Role <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-select dense outlined flat :items="roles" v-model="form.role" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2">
          <v-col cols="12" class="py-0">
            <v-btn color="success" @click="createUser">
              <v-icon>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2">
          <v-col cols="12" class="py-0">
            <v-btn color="primary" @click="back">
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
export default {
  name: "create-user",
  data: () => ({
    form: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobile: "",
      role: "user",
    },
    roles: [
      { value: "admin", text: "ROLE_ADMIN" },
      { value: "manager", text: "ROLE_MANAGER" },
      { value: "user", text: "ROLE_USER" },
      { value: "finance", text: "ROLE_FINANCE" },
      { value: "accounting", text: "ROLE_ACCOUNTING" },
      { value: "director", text: "ROLE_DIRECTOR" },
    ],
  }),
  methods: {
    createUser() {
      this.$store.dispatch("user/create", this.form);
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
